import React from 'react';
import { useSetRecoilState } from 'recoil';
import ReactApexChart from "react-apexcharts";

import { extractChartItemsWithMatchingEventDates } from '../../utils/chartUtils';
import { fetchSleepGraphData } from '../../HTTPS/GraphApis';
import { groupByDateForLineGraph, localisingTimestamp, processGroupedDataForLineGraph } from '../../utils/reuseableFunc';
import useVitalChartQuery from '../../hooks/useVitalChartQuery';
import { lineChartDataState } from '../../recoil/vitalTables/lineChartDataState';
import { usePatient } from '../../context/PatientContext';


function SleepChart({ events }) {
  const numberOfDaysToFetch = 35;
  const numberOfDaysToShow = 7;
  const { patient } = usePatient();

  const id = "sleep";
  const chartName = patient?.fname + "_" + id

  const setChartData = useSetRecoilState(lineChartDataState);

  // calling chart data
  const { data: chartData, isFetched, isLoading } = useVitalChartQuery(
    "sleepGraph",
    fetchSleepGraphData,
    numberOfDaysToFetch
  );


  // Check if chartData is available before rendering the chart
  if (isLoading) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      <p>Loading...</p>
    </div>;
  }

  // Check if chartData is available before rendering the chart
  if (!isFetched || !(chartData?.length > 0)) {
    setChartData([])
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      <p>No Data Found</p>
    </div>;
  }
  const localizedChartData = localisingTimestamp(chartData, "calendar_date")

  const groupedByDate = groupByDateForLineGraph(localizedChartData)

  const originalCopyLight = processGroupedDataForLineGraph(groupedByDate, 'light', numberOfDaysToShow, 'add')
  const originalCopyDeep = processGroupedDataForLineGraph(groupedByDate, 'deep', numberOfDaysToShow, 'add')
  const originalCopyRem = processGroupedDataForLineGraph(groupedByDate, 'rem', numberOfDaysToShow, 'add')
  const originalCopyTotal = processGroupedDataForLineGraph(groupedByDate, 'total', numberOfDaysToShow, 'add')

  const dataTable = []

  for (let i = 0; i < originalCopyLight.length; i++) {
    let item = [...originalCopyTotal.at(i), originalCopyLight.at(i).at(1), originalCopyDeep.at(i).at(1), originalCopyRem.at(i).at(1),]
    dataTable.push(item)
  }
  
  setChartData(dataTable)

  const roundOffedTotal = JSON.parse(JSON.stringify(originalCopyTotal));
  const roundOffedLight = JSON.parse(JSON.stringify(originalCopyLight));
  const roundOffedDeep = JSON.parse(JSON.stringify(originalCopyDeep));
  const roundOffedRem = JSON.parse(JSON.stringify(originalCopyRem));
  return (
    <>
      <div className="w-full mt-10 border-8 border-black/40 rounded-lg">
        <ReactApexChart
          type="line"
          series={[
            {
              name: "Total",
              type: 'line',
              data: roundOffedTotal
            },
            {
              name: "Light",
              type: 'line',
              data: roundOffedLight
            },
            {
              name: "Deep",
              type: 'line',
              data: roundOffedDeep
            },
            {
              name: "REM",
              type: 'line',
              data: roundOffedRem
            },
            {
              name: 'Activities',
              type: 'scatter',
              data: extractChartItemsWithMatchingEventDates(events, roundOffedLight)
            },
          ]}
          width="100%"
          height="380"
          options={{
            chart: {
              id,
              parentHeightOffset: 0,
              toolbar: {
                show: true,
                export: {
                csv: {
                  filename: chartName,
                  headerCategory: "Date",
                },
                svg: {
                  filename: chartName
                },
                png: {
                  filename: chartName
                }
              }
              },
              foreColor: "#FAFCFE", // color of the legend's text
              background: 'rgba(0, 0, 0, 0.4)',
            },
            stroke: {
              width: 3,
              // curve: "smooth",
              // colors: [], // colors the sleep lines
            },
            dataLabels: {
              enabled: false,
            },
            markers: { // this is for dot on the graph
              size: [0, 0, 0, 0, 8],
              strokeWidth: 0,
              colors: ['#ED7117ff'],
            },
            tooltip: { // tooltip setting when we hover over the charts dots
              enabled: false,
            },
            legend: { // this is for name of the graphs at the bottom
              markers: {
                // eslint-disable-next-line no-sparse-arrays
                fillColors: [, , , , '#ED7117ff']
              }
            },
            grid: { // bg grid lines of the graph
              borderColor: "rgba(236, 241, 244, 0.4)",
            },
            xaxis: {
              type: "datetime",
              axisTicks: { show: true },
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default SleepChart;